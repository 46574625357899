// src/pages/AboutPage.jsx
import React from 'react';
import aboutPict from '../assets/about.jpg';

function About() {
  return (
    <section className="about-section py-5" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <div className='d-flex flex-row gap-3'>
            <h1 className="text-primary">Hakkımızda / </h1>
            <h1>Tire Bilişim</h1>
            </div>
            <p className="lead">
              Yazılım dünyasında yenilikçi ve güvenilir çözümler sunan bir teknoloji partneriyiz. Amacımız, müşterilerimizin iş süreçlerini optimize ederek verimliliklerini artırmak, dijital dönüşümlerini hızlandırmak ve sektörde sürdürülebilir bir rekabet avantajı sağlamaktır.
            </p>
            <p>
              <strong>Vizyonumuz</strong>, teknolojinin gücünü kullanarak müşterilerimize en yüksek kalitede yazılım çözümleri sunmak, onların iş hedeflerine ulaşmalarını sağlamak ve sektörde lider bir konuma yükselmektir. 
              Müşteri memnuniyetini her zaman ön planda tutarak, inovasyonu ve sürekli iyileştirmeyi temel prensiplerimiz olarak benimsiyoruz.
            </p>
            <p>
              <strong>Misyonumuz</strong>, müşterilerimizin ihtiyaçlarına özel, etkili ve ölçeklenebilir yazılım çözümleri geliştirerek onların iş süreçlerinde mükemmellik sağlamalarına katkıda bulunmaktır. 
              Projelerimizin her aşamasında müşteri odaklı bir yaklaşım benimseyerek, kaliteli ve zamanında teslimat yapmayı taahhüt ediyoruz. 
              Güvenilirlik, şeffaflık ve sürekli iletişim, bizim en değerli ilkelerimizdir.
            </p>
            <p>
              Uzman ekibimizle birlikte, en yeni teknolojileri takip ediyor ve bu teknolojileri müşterilerimizin projelerine entegre ederek, onların işlerini geleceğe taşımalarına yardımcı oluyoruz. 
              Başarıyı birlikte inşa etmek için buradayız.
            </p>
          </div>
          <div className="col-md-6">
            <img src={aboutPict} alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" className="img-fluid rounded" style={{width: "100%"}}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
