import { Helmet } from "react-helmet-async";

const Meta = ({
  title = "PATBİS Uyumlu Depo Yönetim Yazılımı",
  description = "PATBİS uyumlu maden ocağı yazılımı ile güvenli malzeme yönetimi, depo takibi, patlayıcı madde envanter yönetimi, ve süreç optimizasyonu sağlayın.",
  keywords = "PATBİS, maden ocağı, patlayıcı madde yönetimi, depo yazılımı, malzeme yönetimi, envanter takip, maden güvenliği, risk yönetimi, PATBİS uyumlu yazılım, patlayıcı sevkiyatı, malzeme takibi, PATBİS sistemi, güvenli maden yönetimi, patlayıcı envanteri, maden ocağı yazılımı, patlayıcı izleme sistemi, PATBİS entegrasyonu, maden otomasyonu, PATBİS uyumlu depo, maden operasyonları, güvenli sevkiyat, lojistik yönetimi"
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default Meta;
