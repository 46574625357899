import apiSlice from "../apiSlice";
import { UPDATE_URL } from "../constants";
  
export const updateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addUpdate: builder.mutation({
      query: (data) => ({
        url: UPDATE_URL,
        method: 'POST',
        body: data,
      }),
    }),
    getUpdates: builder.query({
      query: () => ({
        url: UPDATE_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblupdate"],
    }),
    getUpdateById: builder.query({
      query: (updateID) => ({
        url: `${UPDATE_URL}/${updateID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblupdate"],
    }),
    updateUpdateById: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_URL}/${data.updateID}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteUpdateById: builder.mutation({
      query: (updateID) => ({
        url: `${UPDATE_URL}/${updateID}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddUpdateMutation,
  useGetUpdatesQuery,
  useGetUpdateByIdQuery,
  useUpdateUpdateByIdMutation,
  useDeleteUpdateByIdMutation,
} = updateApi;
