import apiSlice from "../apiSlice";
import { TAHSILAT_URL } from "../constants";
  
export const tahsilatApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addTahsilat: builder.mutation({
      query: (data) => ({
        url: TAHSILAT_URL,
        method: 'POST',
        body: data,
      }),
    }),
    getTahsilats: builder.query({
      query: (customerID) => ({
        url: `${TAHSILAT_URL}/filter/${customerID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbltahsilat"],
    }),
    getTahsilatById: builder.query({
      query: (tahsilatID) => ({
        url: `${TAHSILAT_URL}/${tahsilatID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbltahsilat"],
    }),
    updateTahsilatById: builder.mutation({
      query: (data) => ({
        url: `${TAHSILAT_URL}/${data.tahsilatID}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteTahsilatById: builder.mutation({
      query: (tahsilatID) => ({
        url: `${TAHSILAT_URL}/${tahsilatID}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddTahsilatMutation,
  useGetTahsilatsQuery,
  useGetTahsilatByIdQuery,
  useUpdateTahsilatByIdMutation,
  useDeleteTahsilatByIdMutation,
} = tahsilatApi;
