import React from 'react';

const Spinner = () => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column', // Dikey hizalama için
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Arka planı karartmak için
    zIndex: 9999, // Overlay'nin üstte görünmesi için
    color: 'white', // Metin rengi beyaz
  };

  const textStyle = {
    marginTop: '20px', // Spin ile metin arasındaki boşluk
    color: 'white', // Metin rengini beyaz yap
    fontSize: '18px', // Metin boyutu
    textAlign: 'center', // Metni ortalama
  };

  return (
    <div style={overlayStyle}>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>
      <div style={textStyle}>İşlemleriniz Yapılıyor Lütfen Bekleyin</div>
    </div>
  );
};

export default Spinner;
