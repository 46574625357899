import React, { useEffect, useState } from "react";
import MusteriIslemleri from "./MusteriIslemleri";
import DuyuruIslemleri from "./DuyuruIslemleri";
import MusteriTanim from "./MusteriTanim";
import ServerTanim from "./ServerTanim";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../slices/auth/authSlice";

const DashBoard = () => {
  const {user} = useSelector(state => state.dynoAdminAuth)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("sunucu");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if(user && (!user || !user.token)) {
      dispatch(logout())
      navigate("/")
    }
  },[dispatch, navigate, user])

  return (
    <div className="dyno-menu container">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "sunucu" ? "active text-white bg-primary" : ""
            }`}
            onClick={() => handleTabClick("sunucu")}
          >
            Sunucu İşlemleri
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "musteritanim" ? "active text-white bg-primary" : ""
            }`}
            onClick={() => handleTabClick("musteritanim")}
          >
            Müşteri Tanımları
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "musteri" ? "active text-white bg-primary" : ""
            }`}
            onClick={() => handleTabClick("musteri")}
          >
            Müşteri İşlemleri
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "duyuru" ? "active text-white bg-primary" : ""
            }`}
            onClick={() => handleTabClick("duyuru")}
          >
            Duyuru İşlemleri
          </button>
        </li>
      </ul>

      <div className="tab-content mt-4">
        {activeTab === "sunucu" && <ServerTanim />}
        {activeTab === "musteritanim" && <MusteriTanim />}
        {activeTab === "musteri" && <MusteriIslemleri />}
        {activeTab === "duyuru" && <DuyuruIslemleri />}
      </div>
    </div>
  );
};

export default DashBoard;
