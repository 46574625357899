import React, { useState, useEffect } from "react";
import { useAddMusteriMutation, useUpdateMusteriByIdMutation } from "../slices/redux/MusteriSlice";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setReloadTable } from "../slices/auth/authSlice";
import { FaBackspace, FaSave, FaSyncAlt } from "react-icons/fa";

const MusteriForm = ({ selectedMusteri, handleCancel }) => {
  const dispatch = useDispatch();
  const [updateMusteriById, { data: upInfo, isLoading, isError, message }] =
    useUpdateMusteriByIdMutation();
  const [addMusteri, {data: addInfo}] = useAddMusteriMutation()

  const [musteri, setMusteri] = useState({
    customerID: "",
    customerName: "",
    customerPerson: "",
    customerAddress: "",
    customerDomain: "",
    customerDBName: "",
    customerRunPort: "",
  });
  let {
    customerName,
    customerPerson,
    customerAddress,
    customerDomain,
    customerDBName,
    customerRunPort,
  } = musteri;

  useEffect(() => {
    if (selectedMusteri) {
      setMusteri(selectedMusteri);
    } else {
      setMusteri({
        customerID: "",
        customerName: "",
        customerPerson: "",
        customerAddress: "",
        customerDomain: "",
        customerDBName: "",
        customerRunPort: "",
      });
    }
  }, [selectedMusteri]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMusteri({ ...musteri, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      await updateMusteriById(musteri).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    setMusteri({
      customerID: "",
      customerName: "",
      customerPerson: "",
      customerAddress: "",
      customerDomain: "",
      customerDBName: "",
      customerRunPort: "",
    });
  };

  const handleSubmit = async () => {
    try {
      await addMusteri(musteri).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    setMusteri({
      customerID: "",
      customerName: "",
      customerPerson: "",
      customerAddress: "",
      customerDomain: "",
      customerDBName: "",
      customerRunPort: "",
    });
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      dispatch(setReloadTable(true));
      handleCancel()
    }
  }, [upInfo, dispatch]);

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      dispatch(setReloadTable(true));
      handleCancel()
    }
  }, [addInfo, dispatch]);

  return (
    <>
      {isLoading && <Spinner />}
      <section className="d-flex flex-row gap-3">
        <div className="d-flex flex-column col-6">
          <div className="form-group">
            <label>Firma Adı</label>
            <input
              type="text"
              className="form-control"
              name="customerName"
              value={customerName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>İrtibat Kişisi</label>
            <input
              type="text"
              className="form-control"
              name="customerPerson"
              value={customerPerson}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Adres Bilgisi</label>
            <input
              type="text"
              className="form-control"
              name="customerAddress"
              value={customerAddress}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column col-6">
          <div className="form-group">
            <label>Sub Domain</label>
            <input
              type="text"
              className="form-control"
              name="customerDomain"
              value={customerDomain}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Veritabanı Adı</label>
            <input
              type="text"
              className="form-control"
              name="customerDBName"
              value={customerDBName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Node Port</label>
            <input
              type="text"
              className="form-control"
              name="customerRunPort"
              value={customerRunPort}
              onChange={handleChange}
            />
          </div>
        </div>
      </section>
      <div className="d-flex flex-column col-6">
        <div className="form-group">
          <span></span>
          {selectedMusteri ? (
            <div className="d-flex flex-row gap-1">
              <button
                type="button"
                className="btn btn-primary w-100 col-8 mt-1"
                onClick={handleUpdate}
              >
                <FaSyncAlt /> Güncelle
              </button>
              <button
                type="button"
                className="btn btn-secondary w-100 col-4"
                onClick={() => handleCancel()}
              >
                <FaBackspace /> Vazgeç
              </button>
            </div>
          ) : (
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={handleSubmit}
              >
                <FaSave /> Kaydet
              </button>
            </div>
          )}
        </div>
      </div>
      {isError && <div className="alert alert-danger">{message}</div>}
    </>
  );
};

export default MusteriForm;
