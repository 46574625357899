import React, { useState } from "react";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { SERVERPICT_URL } from "../slices/constants";

const DuyuruTable = ({ duyurular, onEdit, onDelete }) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [duyuruToDelete, setDuyuruToDelete] = useState(null);

  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const confirmDelete = (duyuru) => {
    setDuyuruToDelete(duyuru);
    setDeleteDialogVisible(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteDuyuru = () => {
    onDelete(duyuruToDelete);
    hideDeleteDialog();
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "12%" }}>İşlemler</th>
            <th style={{ width: "15%" }}>Başlık</th>
            <th style={{ width: "42%" }}>Duyuru Metni</th>
            <th style={{ width: "10%" }}>Resim URL</th>
            <th style={{ width: "10%" }}>Video URL</th>
            <th style={{ width: "10%" }}>Tarih</th>
          </tr>
        </thead>
        <tbody>
          {duyurular.slice(first, first + rows).map((duyuru) => (
            <tr key={duyuru.updateID}>
              <td className="d-flex flex-row gap-1">
                <button
                  className="btn btn-warning btn-sm"
                  onClick={() => onEdit(duyuru)}
                >
                  <FaEdit /> Düzenle
                </button>
                <button
                  className="btn btn-danger btn-sm ml-2"
                  onClick={() => confirmDelete(duyuru)}
                >
                  <FaTrashAlt /> Sil
                </button>
              </td>
              <td>{duyuru.updateTitle}</td>
              <td>{duyuru.updateText}</td>
              <td>
                <img onClick={() => window.open(`${SERVERPICT_URL}${duyuru.updatePictureUrl}`, "_blank")}  
                  src={`${SERVERPICT_URL}${duyuru.updatePictureUrl}`}
                  alt=""
                  style={{ width: "70px" }}
                  className="cursor-pointer"
                />
              </td>
              <td>{duyuru.updateVideoUrl}</td>
              <td>{duyuru.updateDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={duyurular.length}
        onPageChange={onPageChange}
      />

      <Dialog
        header="Duyuru Sil"
        visible={deleteDialogVisible}
        onHide={hideDeleteDialog}
        footer={
          <div>
            <button className="btn btn-danger" onClick={deleteDuyuru}>
              Evet
            </button>
            <button className="btn btn-secondary" onClick={hideDeleteDialog}>
              Hayır
            </button>
          </div>
        }
      >
        <p>Bu duyuruyu silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </div>
  );
};

export default DuyuruTable;
