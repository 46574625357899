import React, { useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import { Calendar } from "primereact/calendar";
import {
  useGetSatissQuery,
  useAddSatisMutation,
  useDeleteSatisByIdMutation
} from "../slices/redux/SatisSlice";
import Spinner from "../components/Spinner";
import { FaBackspace, FaSave, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setReloadTable } from "../slices/auth/authSlice";
import { Dialog } from "primereact/dialog";
import { primeReactStyle } from "../components/PrimeReactUtil";
import moment from "moment";

const SatisIslemleri = ({ customerID }) => {
  const dispatch = useDispatch();
  const {
    data: satisTable,
    isLoading,
    isError,
    message,
    refetch
  } = useGetSatissQuery(customerID);
  const [addSatis, {data: addInfo}] = useAddSatisMutation();
  const [deleteSatisById, {data: delInfo}] = useDeleteSatisByIdMutation();
  const [satisData, setSatisData] = useState({
    yazilimTutari: "",
    cihazTutari: "",
    baslangicTarihi: "",
    bitisTarihi: "",
  });
  const { yazilimTutari, cihazTutari, baslangicTarihi, bitisTarihi } = satisData;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [visible, setVisible] = useState(false);
  const [silID, setSilID] = useState("");

  const silSorModul = (satis) => {
    setSilID(satis.saleID);
    setVisible(true);
  };

  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const handleChange = (e) => {
    setSatisData({
      ...satisData,
      [e.target.name]: e.target.value,
    });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.value);
    setSatisData({
      ...satisData,
      baslangicTarihi: e.value.toISOString().split("T")[0],
    });
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.value);
    setSatisData({
      ...satisData,
      bitisTarihi: e.value.toISOString().split("T")[0],
    });
  };

  const satisKaydet = async () => {
    if(baslangicTarihi === "" && bitisTarihi === "") {
      toast.error("Lütfen başlangıç ve bitiş tarihlerini seçin.");
      return;
    } 
    let data = { ...satisData, customerID };
    try {
      await addSatis(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      dispatch(setReloadTable(true));
    }
  }, [addInfo, dispatch]);

  const handleDeleteSatis = async () => {
    try {
      await deleteSatisById(silID).unwrap();
      setVisible(false);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [delInfo, refetch]);

  useEffect(() => {
    if(!customerID) {
      refetch()
    }
  },[customerID, refetch])

  return (
    <div className="col-8 border-right">
      {isLoading && <Spinner />}
      <div className="col-12 d-flex flex-row gap-3">
        <div className="d-flex flex-column gap-3 w-50">
          <div className="row">
            <label className="col-4 mt-2">Yazılım Tutarı</label>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                name="yazilimTutari"
                value={yazilimTutari}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <label className="col-4 mt-2">Cihaz Tutarı</label>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                name="cihazTutari"
                value={cihazTutari}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-3 w-50">
          <div className="row">
            <label className="col-4 mt-2">Başlangıç Tarihi</label>
            <div className="col-8">
              <Calendar
                value={startDate}
                onChange={handleStartDateChange}
                dateFormat="yy-mm-dd"
                showIcon
                className="w-100"
              />
            </div>
          </div>

          <div className="row">
            <label className="col-4 mt-2">Bitiş Tarihi</label>
            <div className="col-8">
              <Calendar
                value={endDate}
                onChange={handleEndDateChange}
                dateFormat="yy-mm-dd"
                showIcon
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-100 mt-2">
        <button className="btn btn-success" onClick={satisKaydet}>
          <FaSave /> Kaydet
        </button>
      </div>
      <hr className="my-2" />
      <table className="table table-striped">
        <thead>
          <tr>
            <th>İşlem</th>
            <th>Başlangıç Tarihi</th>
            <th>Bitiş Tarihi</th>
            <th>Yazılım Tutarı</th>
            <th>Cihaz Tutarı</th>
          </tr>
        </thead>
        {satisTable && satisTable.length > 0 && (
          <tbody>
            {satisTable.slice(first, first + rows).map((satis) => (
              <tr key={satis.id}>
                <td><button className="btn btn-danger" onClick={() => silSorModul(satis)}>Sil</button></td>
                <td>{moment(satis.startDate).format("DD.MM.YYYYY")}</td>
                <td>{moment(satis.endDate).format("DD.MM.YYYYY")}</td>
                <td>{satis.softwarePrice}</td>
                <td>{satis.pdaPrice}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={satisTable && satisTable.length}
        onPageChange={onPageChange}
      />
      {isError && <div className="alert alert-danger">{message}</div>}
      <Dialog
        header="Kayıt Silme Onayı"
        visible={visible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu <strong>Satış Kaydı</strong>{" "}
            Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDeleteSatis}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </div>
  );
};

export default SatisIslemleri;
