import React, { useState, useEffect } from "react";
import { useAddSunucuMutation, useUpdateSunucuByIdMutation } from "../slices/redux/ServerSlice";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setReloadTable } from "../slices/auth/authSlice";
import { FaBackspace, FaSave, FaSyncAlt } from "react-icons/fa";

const ServerForm = ({ selectedServer, handleCancel }) => {
  const dispatch = useDispatch();
  const [addSunucu] = useAddSunucuMutation();
  const [updateSunucuById, { data: upInfo, isLoading, isError, message }] = useUpdateSunucuByIdMutation();

  const [server, setServer] = useState({
    sunucuFirma: "",
    sunucuURL: "",
    sunucuUserName: "",
    sunucuPassword: "",
    sunucuHizmeti: "",
    sunucuBitisTarihi: "",
  });

  const { sunucuFirma, sunucuURL, sunucuUserName, sunucuPassword, sunucuHizmeti, sunucuBitisTarihi } = server;

  useEffect(() => {
    if (selectedServer) {
      setServer(selectedServer);
    } else {
      setServer({
        sunucuFirma: "",
        sunucuURL: "",
        sunucuUserName: "",
        sunucuPassword: "",
        sunucuHizmeti: "",
        sunucuBitisTarihi: "",
      });
    }
  }, [selectedServer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setServer({ ...server, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (selectedServer) {
        await updateSunucuById(server).unwrap();
      } else {
        await addSunucu(server).unwrap();
      }
      dispatch(setReloadTable(true));
      setServer({
        sunucuFirma: "",
        sunucuURL: "",
        sunucuUserName: "",
        sunucuPassword: "",
        sunucuHizmeti: "",
        sunucuBitisTarihi: "",
      });
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      dispatch(setReloadTable(true));
    }
  }, [upInfo, dispatch]);

  return (
    <>
      {isLoading && <Spinner />}
      <section className="d-flex flex-row gap-3">
        <div className="d-flex flex-column col-6">
          <div className="form-group">
            <label>Firma Adı</label>
            <input
              type="text"
              className="form-control"
              name="sunucuFirma"
              value={sunucuFirma}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>www Url</label>
            <input
              type="text"
              className="form-control"
              name="sunucuURL"
              value={sunucuURL}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Kullanıcı Adı</label>
            <input
              type="text"
              className="form-control"
              name="sunucuUserName"
              value={sunucuUserName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column col-6">
          <div className="form-group">
            <label>Şifre</label>
            <input
              type="password"
              className="form-control"
              name="sunucuPassword"
              value={sunucuPassword}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Hizmet Adı</label>
            <input
              type="text"
              className="form-control"
              name="sunucuHizmeti"
              value={sunucuHizmeti}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Bitiş Tarihi</label>
            <input
              type="date"
              className="form-control"
              name="sunucuBitisTarihi"
              value={sunucuBitisTarihi}
              onChange={handleChange}
            />
          </div>
        </div>
      </section>
      <div className="d-flex flex-column col-6">
        <div className="form-group">
          <span></span>
          {selectedServer ? (
            <div className="d-flex flex-row gap-1">
              <button
                type="button"
                className="btn btn-primary w-100 col-8 mt-1"
                onClick={handleSubmit}
              >
                <FaSyncAlt /> Güncelle
              </button>
              <button
                type="button"
                className="btn btn-secondary w-100 col-4"
                onClick={() => handleCancel()}
              >
                <FaBackspace /> Vazgeç
              </button>
            </div>
          ) : (
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={handleSubmit}
              >
                <FaSave /> Kaydet
              </button>
            </div>
          )}
        </div>
      </div>
      {isError && <div className="alert alert-danger">{message}</div>}
    </>
  );
};

export default ServerForm;
