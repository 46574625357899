import React, { useEffect, useState } from "react";
import ServerForm from "./ServerForm";
import ServerTable from "./ServerTable";
import { useGetSunucusQuery } from "../slices/redux/ServerSlice";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";

const ServerTanim = () => {
  const { reloadTable } = useSelector((state) => state.dynoAdminAuth); // reloadTable Redux state'inden geliyor
  const { data: sunucularTable, isLoading, isError, message, refetch } = useGetSunucusQuery();
  const [sunucular, setSunucular] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);

  useEffect(() => {
    if (sunucularTable) {
      setSunucular(sunucularTable);
    }
  }, [sunucularTable]);

  const handleEdit = (server) => {
    setSelectedServer(server);
  };

  const handleDelete = (server) => {
    setSunucular(sunucular.filter((s) => s.id !== server.id));
  };

  useEffect(() => {
    if (reloadTable) {
      refetch(); // Tablo yenileme durumu olduğunda refetch yapılıyor
    }
  }, [reloadTable, refetch]);

  return (
    <div className="tab-menu">
      {isLoading && <Spinner />} {/* Spinner, yüklenme durumunda gösteriliyor */}
      <ServerForm selectedServer={selectedServer} handleCancel={() => setSelectedServer(null)} />
      <hr className="my-2" />
      <ServerTable sunucular={sunucular} onEdit={handleEdit} onDelete={handleDelete} />
      {isError && <div className="error-message">{message}</div>}
    </div>
  );
};

export default ServerTanim;
