// src/components/Hero.jsx
import React from "react";
import slider1 from "../assets/slider1.jpg";
import slider2 from "../assets/slider2.jpg";
import slider3 from "../assets/slider3.jpg";

function Hero() {
  return (
    <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={slider1} className="d-block w-100" alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" />
          <div className="carousel-caption d-none d-md-block">
            <h1 className="font-24"><strong>En Gelişmiş Teknolojilerle Güvenliği Sağlıyoruz</strong></h1>
            <p>
              Web sitelerinizi ve uygulamalarınızı, en ileri teknolojilerle
              güvenli bir şekilde kodluyoruz. Güvenliğinizi her şeyin önünde
              tutuyoruz.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img src={slider2} className="d-block w-100" alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" />
          <div className="carousel-caption d-none d-md-block">
            <h1 className="font-24"><strong>İhtiyaçlarınıza Özel Çözümler Sunuyoruz</strong></h1>
            <p>
              Müşteri taleplerine ve mevcut yapılarına en uygun çözümleri
              sunuyoruz. Uygulamalarınızı, işlevselliği ve kullanım kolaylığını
              bir arada sağlayacak şekilde özenle tasarlıyoruz.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img src={slider3} className="d-block w-100" alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" />
          <div className="carousel-caption d-none d-md-block">
            <h1 className="font-24"><strong>Kolay Kullanım, Derinlemesine Detay</strong></h1>
            <p>
              Web sitelerimiz ve uygulamalarımız, kullanıcı dostu yüzeylerinin
              arkasında karmaşık ve güçlü detaylarla desteklenir. Basitliği ve
              işlevselliği bir araya getiriyoruz.
            </p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#heroCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Önceki</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#heroCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Sonraki</span>
      </button>
    </div>
  );
}

export default Hero;
