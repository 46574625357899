import React from "react";
import { useNavigate } from "react-router-dom";
import software1 from "../assets/app/dynoblastbarkod.png";
import software2 from "../assets/app/mahsul.png";
import software3 from "../assets/app/nakliye.png";

function Yazilimlarimiz() {
  const navigate = useNavigate();

  return (
    <section className="software-section py-5 bg-light" id="yazilimlarimiz">
      <div className="container">
        <h2 className="text-center text-primary mb-5">Yazılımlarımız</h2>
        <div className="row">
          <div className="col-md-4">
            <div
              className="card mb-4 shadow-sm cursor-pointer"
              onClick={() => navigate("/dynoblastdetail")}
            >
              <img src={software1} className="card-img-top p-3 mb-4" alt="Dynoblast Barkod" />
              <div className="card-body">
                <h1 className="card-title font-24">Dynoblast Barkod</h1>
                <p className="card-text">
                  Patlayıcı madde kullanımı yapan maden ocakları ve benzeri
                  alanlar için, PATBİS sistemine tam entegre raporlar hazırlayan
                  ve size sunan profesyonel bir uygulamadır.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="card mb-4 shadow-sm cursor-pointer"
              // onClick={() => navigate("/mahsuldetail")}
            >
              <img src={software2} className="card-img-top p-3 mb-4" alt="Mahsul" />
              <div className="card-body">
                <h1 className="card-title font-24">Mahsul</h1>
                <p className="card-text">
                  Çiftçilerin ürettiği tüm tahıl ve benzeri ürünlerin
                  alım-satımını yapan aracı firmalar için, çiftçi, servis,
                  firma, nakliye, araç, personel ve işyeri giderleri gibi tüm
                  işlemleri bir araya getiren dünyadaki tek yazılım.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="card mb-4 shadow-sm cursor-pointer"
             // onClick={() => navigate("/nakliyedetail")}
            >
              <img src={software3} className="card-img-top p-3" alt="Nakliye" />
              <div className="card-body">
                <h1 className="card-title font-24">Nakliye</h1>
                <p className="card-text">
                  Nakliye işlemleri yapan tüm firmalar ve komisyoncular için,
                  kiralama, hizmet, araç ve personel, şirketin kendi giderleri
                  ile şoför işlemlerini kapsayan kapsamlı bir çözüm.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Yazilimlarimiz;
