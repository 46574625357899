import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "./constants";

import { logout } from "./auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER_URL,
});

async function baseQueryWithAuth(args, api, extra) {
  const { getState } = api;
  const state = getState();
  if (state.dynoAdminAuth && state.dynoAdminAuth.user && state.dynoAdminAuth.user.token) {
    args.headers = {
      ...args.headers,
      "token": state.dynoAdminAuth.user.token,
      "user": state.dynoAdminAuth.user.userCredential
    };
  }
  const result = await baseQuery(args, api, extra);
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
}

const apiSlice = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    "tblusers",
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
