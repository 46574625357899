import React from 'react';

const ToplamBorclar = ({ customerID }) => {
  // customerID'ye göre toplam borç, toplam tahsilat ve kalan bakiye hesaplanır
  const toplamBorç = 1000;
  const toplamTahsilat = 500;
  const kalanBakiye = toplamBorç - toplamTahsilat;

  return (
    <div className="row mt-4">
      <div className="col-4">
        <h5>Toplam Borç: {toplamBorç.toFixed(2)} TL</h5>
      </div>
      <div className="col-4">
        <h5>Toplam Tahsilat: {toplamTahsilat.toFixed(2)} TL</h5>
      </div>
      <div className="col-4">
        <h5>Kalan Bakiye: {kalanBakiye.toFixed(2)} TL</h5>
      </div>
    </div>
  );
};

export default ToplamBorclar;
