import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: sessionStorage.getItem("dynoAdmin.user")
    ? JSON.parse(sessionStorage.getItem("dynoAdmin.user"))
    : localStorage.getItem("dynoAdmin.user")
    ? JSON.parse(localStorage.getItem("dynoAdmin.user"))
    : null,
  reloadTable: false,
};

const authSlice = createSlice({
  name: "dynoAdminAuth",
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
      localStorage.removeItem("dynoAdmin.user");
      sessionStorage.removeItem("dynoAdmin.user");
    },
    setCredentials: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("dynoAdmin.user", JSON.stringify(action.payload));
      sessionStorage.setItem("dynoAdmin.user", JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("dynoAdmin.user");
      sessionStorage.removeItem("dynoAdmin.user");
    },
    setReloadTable: (state, action) => {
      state.reloadTable = action.payload;
    },
  },
});

export const { reset, setCredentials, logout, setReloadTable } = authSlice.actions;

export default authSlice.reducer;
