import React from "react";
import { useNavigate } from "react-router-dom";
import mal_kabul_Pict from "../assets/app/dynoblastbarkod/mal_kabul.jpg";
import mal_kabul_menu_Pict from "../assets/app/dynoblastbarkod/mal_kabul_menu.jpg";
import mal_kabul_xml_Pict from "../assets/app/dynoblastbarkod/mal_kabul_xml.jpg";
import mal_kabul_gosterim_Pict from "../assets/app/dynoblastbarkod/xml_gosterim.jpg";
import mal_kabul_detail_Pict from "../assets/app/dynoblastbarkod/mal_kabul_id_detail.jpg";

function DynoblastDetail() {
  const navigate = useNavigate();
  const kisaLink = "-AQix5Bkrmc";

  return (
    <section className="dynoblast-detail py-5">
      <div className="container">
        <button className="btn btn-primary" onClick={() => navigate("/")}>
          Geri Dön
        </button>
        <h2 className="text-center text-primary mb-4">
          Dynoblast Barkod Detayları
        </h2>

        <div className="mb-4">
          <img
            src={mal_kabul_Pict}
            className="img-fluid mb-3"
            alt="Dynoblast Barkod Kullanım Ekranı 1"
          />
          <p>
            Dynoblast Barkod, maden ocakları ve benzeri alanlarda patlayıcı
            madde kullanımı yapan işletmeler için özel olarak geliştirilmiş bir
            uygulamadır. PATBİS sistemine tam entegre olarak çalışan bu yazılım,
            raporlama süreçlerini kolaylaştırır ve düzenli hale getirir.
          </p>
        </div>

        <div className="mb-4">
          <img
            src={mal_kabul_menu_Pict}
            className="img-fluid mb-3"
            alt="Dynoblast Barkod Kullanım Ekranı 2"
          />
          <p>
            Uygulamanın kullanıcı dostu arayüzü, en karmaşık süreçleri bile
            basit bir şekilde yönetmenizi sağlar. Barkod tarayıcı ile entegre
            çalışarak, sahada hızlı ve hatasız veri girişine olanak tanır.
          </p>
        </div>

        <div className="mb-4">
          <img
            src={mal_kabul_xml_Pict}
            className="img-fluid mb-3"
            alt="Dynoblast Barkod Kullanım Ekranı 3"
          />
          <p>
            Dynoblast Barkod, sahada yapılan işlemleri gerçek zamanlı olarak
            merkeze ileterek, iş güvenliğini artırır. Tüm veri akışı, merkezde
            güvenli bir şekilde saklanır ve yönetilir.
          </p>
        </div>

        <div className="mb-4">
          <img
            src={mal_kabul_gosterim_Pict}
            className="img-fluid mb-3"
            alt="Dynoblast Barkod Kullanım Ekranı 4"
          />
          <p>
            Güvenlik raporları, sahada yapılan işlemlerin tamamını kapsar ve
            ilgili yetkililere anında iletilir. Bu sayede, iş güvenliği ve yasal
            uyumluluk en üst düzeyde sağlanır.
          </p>
        </div>

        <div className="mb-4">
          <img
            src={mal_kabul_detail_Pict}
            className="img-fluid mb-3"
            alt="Dynoblast Barkod Kullanım Ekranı 5"
          />
          <p>
            Dynoblast Barkod, farklı kullanıcı rolleri ve yetkilendirmeler ile
            her seviyede güvenli erişim sağlar. Yöneticiler, raporları kolayca
            görüntüleyebilir ve analiz edebilir.
          </p>
        </div>

        <div className="mb-4">
          <iframe
            width="1280"
            height="720"
            src={`https://www.youtube.com/embed/${kisaLink}?si=2rWXvrZ5fhtxB86-`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <p>
            Yukarıdaki video, Dynoblast Barkod'un nasıl kullanıldığını ayrıntılı
            bir şekilde göstermektedir. Videoda, tüm özellikler adım adım
            açıklanmaktadır.
          </p>
        </div>
      </div>
    </section>
  );
}

export default DynoblastDetail;
