import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "./App";
import NotFound from "./components/NotFound";
import SiteMapPage from "./components/SiteMapPage";
import LoginPage from "./admin/LoginPage";

export const webRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="sitemap" element={<SiteMapPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
