import React, { useEffect, useState } from 'react';
import DuyuruTable from './DuyuruTable';
import DuyuruForm from './DuyuruForm';
import { useGetUpdatesQuery } from '../slices/redux/UpdateSlice';
import Spinner from '../components/Spinner';
import { useSelector } from 'react-redux';

const DuyuruIslemleri = () => {
    const { reloadTable } = useSelector(state => state.dynoAdminAuth);
    const { data: duyuruTable, isLoading, isError, message, refetch } = useGetUpdatesQuery();
    const [duyurular, setDuyurular] = useState([]);
    const [selectedDuyuru, setSelectedDuyuru] = useState(null);

    useEffect(() => {
        if (duyuruTable) {
            setDuyurular(duyuruTable);
        }
    }, [duyuruTable]);

    const handleEdit = (duyuru) => {
        setSelectedDuyuru(duyuru);
    };

    const handleDelete = (duyuru) => {
        setDuyurular(duyurular.filter((d) => d.id !== duyuru.id));
    };

    useEffect(() => {
        if (reloadTable) {
            refetch();
        }
    }, [reloadTable, refetch]);

    return (
        <div className="tab-menu">
            {isLoading && <Spinner />}
            <DuyuruForm selectedDuyuru={selectedDuyuru} handleCancel={() => setSelectedDuyuru(null)} />
            <hr className='my-2' />
            <DuyuruTable duyurular={duyurular} onEdit={handleEdit} onDelete={handleDelete} />
            {isError && <div className="error-message">{message}</div>}
        </div>
    );
};

export default DuyuruIslemleri;
