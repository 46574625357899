import React from 'react';
import { useNavigate } from 'react-router-dom';

function MahsulDetail() {
  const navigate = useNavigate()
  return (
    <section className="mahsul-detail py-5">
      <div className="container">
      <button className='btn btn-primary' onClick={() => navigate("/")}>Geri Dön</button>
      <h2 className="text-center text-primary mb-4">Mahsul Yazılım Detayları</h2>
        
        <div className="mb-4">
          <img src="path_to_image_1" className="img-fluid mb-3" alt="Mahsul Kullanım Ekranı 1" />
          <p>Mahsul yazılımı, çiftçilerin ürettiği tahıl ve benzeri ürünlerin alım-satımını yapan firmalar için geliştirilmiştir. Bu yazılım, çiftçi ve firma işlemlerini tek bir platformda birleştirerek, süreçlerin daha verimli yönetilmesini sağlar.</p>
        </div>

        <div className="mb-4">
          <img src="path_to_image_2" className="img-fluid mb-3" alt="Mahsul Kullanım Ekranı 2" />
          <p>Mahsul yazılımı, çiftçi ve firma işlemlerinin yanı sıra, nakliye ve personel yönetimi gibi karmaşık süreçleri de kapsar. Kullanıcı dostu arayüzü, tüm işlemlerin hızlı ve hatasız bir şekilde gerçekleştirilmesine olanak tanır.</p>
        </div>

        <div className="mb-4">
          <img src="path_to_image_3" className="img-fluid mb-3" alt="Mahsul Kullanım Ekranı 3" />
          <p>Yazılım, tüm işlemleri tek bir platformda toplar ve entegre çözümler sunar. Bu sayede, işletmeler, farklı yazılımlar arasında geçiş yapmak zorunda kalmadan tüm işlemlerini tek bir yerden yönetebilir.</p>
        </div>

        <div className="mb-4">
          <iframe width="100%" height="400" src="https://www.youtube.com/embed/example_video_2" title="Mahsul Yazılımı Kullanım Videosu" allowFullScreen></iframe>
          <p>Yukarıdaki video, Mahsul yazılımının kullanımını detaylı bir şekilde açıklamaktadır. Videoda, yazılımın tüm özellikleri ve işlevleri adım adım gösterilmektedir.</p>
        </div>

        <div className="mb-4">
          <img src="path_to_image_4" className="img-fluid mb-3" alt="Mahsul Kullanım Ekranı 4" />
          <p>Mahsul yazılımı, kullanıcıların günlük iş akışlarını kolaylaştırır ve veri girişi, raporlama gibi işlemleri hızlandırır. Bu sayede, işletmeler zaman ve maliyet tasarrufu sağlar.</p>
        </div>

        <div className="mb-4">
          <img src="path_to_image_5" className="img-fluid mb-3" alt="Mahsul Kullanım Ekranı 5" />
          <p>Güçlü veri analizi özellikleri sayesinde, Mahsul yazılımı, işletmelere geleceğe yönelik stratejik kararlar almada yardımcı olur. Yazılım, verileri detaylı bir şekilde analiz eder ve raporlar sunar.</p>
        </div>
      </div>
    </section>
  );
}

export default MahsulDetail;
