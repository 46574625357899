import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/dynablast_logo_small_yesil.png";
import { useLoginMutation } from "../slices/auth/userApiSlice";
import { setCredentials } from "../slices/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.dynoAdminAuth);
  const [login, { data: loginInfo, isLoading }] = useLoginMutation();
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const remember = true;

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (user && user.token) {
      navigate("/dashboard");
    } else {
      emailRef.current.focus();
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    try {
      let data = { email, password, remember };
      const res = await login(data).unwrap();
      dispatch(setCredentials({ ...res, remember }));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (loginInfo && loginInfo.message) {
      toast(loginInfo.message, { autoClose: 5000 });
    }
  }, [loginInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    else if (name === "password") setPassword(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "email") {
        passwordRef.current.focus();
      } else if (e.target.name === "password") {
        buttonRef.current.focus();
      } else if (e.target.type === "button") {
        handleLogin();
      }
    }
  };

  return (
    <section className="form-signin text-center">
      <section className="form_login">
        <img className="mb-4" src={logo} alt="" width="250" />
        <div className="text-center">
          <h1 className="marka-font">dyno<span className="marka-font" style={{color: "#56b8b9"}}>blast</span><sup>®</sup> 35</h1>
        </div>
        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            name="email"
            value={email}
            placeholder="E-Mail Adresiniz"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            ref={emailRef}
          />
          <label htmlFor="floatingInput">E-Mail Adresiniz</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            name="password"
            value={password}
            placeholder="Password"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            ref={passwordRef}
          />
          <label htmlFor="floatingPassword">Şifreniz</label>
        </div>

        <div className="d-flex flex-row gap-1">
          <div className="form-check text-start my-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="remember"
              value={remember}
              id="flexCheckDefault"
              defaultChecked
              readOnly
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Beni Hatırla
            </label>
          </div>
        </div>
        <button
          className="btn btn-primary w-100 py-2"
          type="button"
          onClick={handleLogin}
          disabled={isLoading}
          ref={buttonRef}
          onKeyDown={handleKeyDown}
        >
          Giriş
        </button>
        <p className="mt-3 mb-3 text-body-secondary">
          <a
            href="https://www.linkedin.com/in/szaferulgur/"
            rel="noreferrer"
            target="_blank"
          >
            tukanSoft
          </a>{" "}
          &copy; 1996–{currentYear}
        </p>
      </section>
    </section>
  );
};

export default LoginPage;
