import apiSlice from "../apiSlice";
import { SUNUCU_URL } from "../constants";
  
export const sunucuApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSunucu: builder.mutation({
      query: (data) => ({
        url: SUNUCU_URL,
        method: 'POST',
        body: data,
      }),
    }),
    getSunucus: builder.query({
      query: () => ({
        url: SUNUCU_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblserver"],
    }),
    getSunucuById: builder.query({
      query: (sunucuID) => ({
        url: `${SUNUCU_URL}/${sunucuID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblserver"], 
    }),
    updateSunucuById: builder.mutation({
      query: (data) => ({
        url: `${SUNUCU_URL}/${data.sunucuID}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteSunucuById: builder.mutation({
      query: (sunucuID) => ({
        url: `${SUNUCU_URL}/${sunucuID}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddSunucuMutation,
  useGetSunucusQuery,
  useGetSunucuByIdQuery,
  useUpdateSunucuByIdMutation,
  useDeleteSunucuByIdMutation,
} = sunucuApi;
