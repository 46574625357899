// src/pages/NelerKodluyoruz.jsx
import React from "react";
import reactLogo from "../assets/script/React.png";
import jwtLogo from "../assets/script/jwt.png";
import sqlLogo from "../assets/script/sql.png";
import nosqlLogo from "../assets/script/nosql.png";
import nodeLogo from "../assets/script/nodejs.png";
import expressjsLogo from "../assets/script/expressjs.png";
import rtkQueryLogo from "../assets/script/rtkquery.png";
import sslLogo from "../assets/script/ssl.png";
import tsxLogo from "../assets/script/tsx.png";
import jsxLogo from "../assets/script/jsx.png";
import bcryptLogo from "../assets/script/bcrypt.png";
import bruteForceLogo from "../assets/script/bruteforce.png";
import sqlInjectionLogo from "../assets/script/sqlinjection.png";
import sqlkeyLogo from "../assets/script/sqlkey.png";

const NelerKodluyoruz = () => {
  return (
    <section className="what-we-code-section py-5" id="nelerkodluyoruz">
      <div className="container">
        <h2 className="text-center text-primary mb-5">
          Neyi & Nasıl Kodluyoruz?
        </h2>

        <div className="row mb-4">
          <div className="col-md-6">
            <div className="card shadow-sm h-100">
              <div className="card-body">
                <h1 className="text-secondary font-24">
                  Web Sitesi ve Web Uygulamaları
                </h1>
                <p>
                  Modern ve kullanıcı dostu web siteleri ve web uygulamaları
                  geliştiriyoruz. Responsive tasarımlar, dinamik içerik yönetimi
                  ve performans optimizasyonu gibi özelliklerle müşterilerimize
                  en iyi çözümleri sunuyoruz. İsterseniz Javascript isterseniz
                  Typescript ile kodlama yapıyoruz. SPA (Single Page
                  Application) yöntemi ile ReactJS'in güç ve performansına RTK
                  Query'nin gelişmiş durum yönetimi ile birleştirilerek
                  kodluyoruz.
                </p>
              </div>
              <div className="card-footer text-center">
                <img src={reactLogo} alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" className="tech-logo" />
                <img
                  src={rtkQueryLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
                <img
                  src={tsxLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
                <img
                  src={jsxLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="card shadow-sm h-100">
              <div className="card-body">
                <h1 className="text-secondary font-24">
                  Backend ve Veritabanı Çözümleri
                </h1>
                <p>
                  Güçlü ve güvenli backend çözümleri üretiyoruz. Node.js,
                  Express, SQL ve NoSQL veritabanları ile ölçeklenebilir ve
                  güvenilir sistemler geliştiriyoruz. MsSQL, MySQL, MariaDB,
                  MongoDB gibi popüler veritabanları ile projelerinizi
                  destekliyoruz.
                </p>
              </div>
              <div className="card-footer text-center">
                <img src={nodeLogo} alt="Node.js Logo" className="tech-logo" />
                <img
                  src={sqlLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
                <img
                  src={nosqlLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
                <img
                  src={expressjsLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-6">
            <div className="card shadow-sm h-100">
              <div className="card-body">
                <h1 className="text-secondary font-24">
                  SQL Injection ve Brute Force Attacklarına Karşı Önlemler
                </h1>
                <p>
                  Güvenlik en önemli önceliğimizdir. SQL injection ve brute
                  force saldırılarına karşı çeşitli güvenlik önlemleri alarak,
                  sistemlerinizi koruyoruz. Parametreli sorgular, şifreleme ve
                  diğer güvenlik uygulamalarını kullanarak, saldırılara karşı
                  dirençli sistemler geliştiriyoruz.
                </p>
              </div>
              <div className="card-footer text-center">
                <img src={sslLogo} alt="SSL Logo" className="tech-logo" />
                <img
                  src={expressjsLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
                <img
                  src={bruteForceLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
                 <img
                  src={sqlInjectionLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="card shadow-sm h-100">
              <div className="card-body">
                <h1 className="text-secondary font-24">JWT Token Önemi</h1>
                <p>
                  JWT token'lar ile kimlik doğrulama ve yetkilendirme
                  süreçlerini güvence altına alıyoruz. Kullanıcı oturumları ve
                  API erişimleri için güvenilir çözümler sunarak,
                  sistemlerinizin güvenliğini sağlıyoruz. BcryptJS ile
                  şifrelerinizi de şifreleyerek veritabanına kayıt ediyoruz..
                  Ayrıca veritabanını admin şifrenizi de bcrypt ile
                  şifreliyoruz.
                </p>
              </div>
              <div className="card-footer text-center">
                <img src={jwtLogo} alt="JWT Logo" className="tech-logo" />
                <img
                  src={bcryptLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
                <img
                  src={sqlkeyLogo}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="tech-logo mx-2 my-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NelerKodluyoruz;
