import React from "react";
import zaferUlgur from "../assets/team/zaferulgur.jpg";
import mustafaCiftci from "../assets/team/mustafaciftci.jpeg";
import serkanYilmaz from "../assets/team/serkanyilmaz.jpg";
import oguzhanKeles from "../assets/team/oguzhankeles.jpeg";

function OurTeam() {
  return (
    <section className="our-team py-5 bg-white">
      <div className="container">
        <h2 className="text-center text-primary mb-5">Ekibimiz</h2>
        <div className="row text-center">
          <div className="col-md-3">
            <img src={serkanYilmaz} alt="Serkan Yılmaz" className="img-fluid rounded-circle" />
            <h1 className="font-24">Serkan Yılmaz</h1>
            <p>Veri Analisti</p>
            <small>Şirket Sahibi</small>
          </div>
          <div className="col-md-3">
            <img src={zaferUlgur} alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" className="img-fluid rounded-circle" />
            <h1 className="font-24">Serhat Zafer Ülgür</h1>
            <p>Bilgisayar Programcısı</p>
            <small>Danışman / Freelance</small>
          </div>
          <div className="col-md-3">
            <img src={oguzhanKeles} alt="Oğuzhan Keleş" className="img-fluid rounded-circle" />
            <h1 className="font-24">Oğuzhan Keleş</h1>
            <p>Mobile Geliştirici</p>
            <small>Danışman / Freelance</small>
          </div>
          <div className="col-md-3">
            <img src={mustafaCiftci} alt="Mustafa Çiftçi" className="img-fluid rounded-circle" />
            <h1 className="font-24">Mustafa Çiftçi</h1>
            <p>Yazılım Geliştirici</p>
            <small>Danışman / Freelance</small>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurTeam;
