import apiSlice from "../apiSlice";
import { MUSTERI_URL } from "../constants";
  
export const musteriApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addMusteri: builder.mutation({
      query: (data) => ({
        url: MUSTERI_URL,
        method: 'POST',
        body: data,
      }),
    }),
    getMusteris: builder.query({
      query: () => ({
        url: MUSTERI_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
    getMusteriById: builder.query({
      query: (customerID) => ({
        url: `${MUSTERI_URL}/${customerID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
    updateMusteriById: builder.mutation({
      query: (data) => ({
        url: `${MUSTERI_URL}/${data.customerID}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteMusteriById: builder.mutation({
      query: (customerID) => ({
        url: `${MUSTERI_URL}/${customerID}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddMusteriMutation,
  useGetMusterisQuery,
  useGetMusteriByIdQuery,
  useUpdateMusteriByIdMutation,
  useDeleteMusteriByIdMutation,
} = musteriApi;
