import React from "react";
import selamiakarPict from "../assets/sites/selamiakar.png";
import aabimhaliyikamaPict from "../assets/sites/aabimhaliyikama.png";
import dynoblastbarkod from "../assets/sites/dynoblastbarkod.png";

const Referanslarimiz = () => {
  return (
    <section className="testimonial-section py-5 bg-light" id="referanslarimiz">
      <div className="container">
        <h2 className="text-center text-primary mb-5">Referanslarımız</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <div className="card-body">
                <img
                  src={selamiakarPict}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="img-fluid mb-3"
                />
                <p className="card-text">
                  "Yazılım firması ile çalışmak harika bir deneyimdi. Tam olarak
                  istediğimiz çözümleri sundular."
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="text-muted">Avukat Selami Akar</small>
                  <a href="https://www.selamiakar.com" target="_blank" rel="noreferrer">
                    selamiakar.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <div className="card-body">
                <img
                  src={aabimhaliyikamaPict}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="img-fluid mb-3"
                />
                <p className="card-text">
                  "Profesyonel ekip ve yenilikçi yaklaşımlarıyla tüm
                  beklentilerimizi aştılar."
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="text-muted">Bülent Can</small>
                  <a href="https://www.aabimhaliyikama.com.tr" target="_blank" rel="noreferrer">
                    aabimhaliyikama.com.tr
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <div className="card-body">
              <img
                  src={dynoblastbarkod}
                  alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer"
                  className="img-fluid mb-4"
                />
                <p className="card-text">
                  "Zamanında teslim ve yüksek kaliteli yazılım çözümleri
                  sundular. Kesinlikle tavsiye ederim!"
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="text-muted">Dinar Andezit Ltd.Şti.</small>
                  <a href="https://dinarandezit.dynoblastizmir.com" target="_blank" rel="noreferrer">
                    dinarandezit.dynoblastizmir.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Referanslarimiz;
