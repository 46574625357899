import apiSlice from "../apiSlice";
import { SATIS_URL } from "../constants";
  
export const satisApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSatis: builder.mutation({
      query: (data) => ({
        url: SATIS_URL,
        method: 'POST',
        body: data,
      }),
    }),
    getSatiss: builder.query({
      query: (customerID) => ({
        url: `${SATIS_URL}/filter/${customerID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsatis"],
    }),
    getSatisById: builder.query({
      query: (satisID) => ({
        url: `${SATIS_URL}/${satisID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsatis"],
    }),
    deleteSatisById: builder.mutation({
      query: (silID) => ({
        url: `${SATIS_URL}/${silID}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddSatisMutation,
  useGetSatissQuery,
  useGetSatisByIdQuery,
  useDeleteSatisByIdMutation,
} = satisApi;
