import React from "react";
import { Link } from 'react-router-dom';
import logo from "../assets/dynablast_logo_small.png"

const NotFound = () => {
  return (
    <div
      style={{
        backgroundColor: "#f4f4f4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh"
      }}
    >
      <div className="notfound-container">
      <img src={logo} alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" style={{ width: "250px" }} className='mb-3' />
      <div className="notfound-404">
        <h1>404</h1>
      </div>
      <h2>Oops! Sayfa bulunamadı.</h2>
      <p>
        Aradığınız sayfa taşınmış, silinmiş veya hiç var olmamış olabilir.
      </p>
      <Link to="/" className="notfound-btn">
        Ana Sayfaya Dön
      </Link>
      <div className="notfound-animation">
        <div className="cloud"></div>
        <div className="cloud"></div>
        <div className="cloud"></div>
      </div>
    </div>
    </div>
  );
};

export default NotFound;
 