import React from "react";
import { useNavigate } from "react-router-dom";

function NakliyeDetail() {
  const navigate = useNavigate();
  return (
    <section className="nakliye-detail py-5">
      <div className="container">
        <button className="btn btn-primary" onClick={() => navigate("/")}>
          Geri Dön
        </button>
        <h2 className="text-center text-primary mb-4">
          Nakliye Yazılım Detayları
        </h2>

        <div className="mb-4">
          <img
            src="path_to_image_1"
            className="img-fluid mb-3"
            alt="Nakliye Kullanım Ekranı 1"
          />
          <p>
            Nakliye yazılımı, nakliye firmaları ve komisyoncular için özel
            olarak tasarlanmış bir çözümdür. Kiralama, hizmet, araç, personel ve
            şoför işlemlerini tek bir platformda toplar.
          </p>
        </div>

        <div className="mb-4">
          <img
            src="path_to_image_2"
            className="img-fluid mb-3"
            alt="Nakliye Kullanım Ekranı 2"
          />
          <p>
            Yazılım, karmaşık nakliye işlemlerini basit ve yönetilebilir hale
            getirir. Araç ve şoför yönetimi, rotalama ve maliyet analizleri gibi
            işlemler, tek bir yerden yönetilebilir.
          </p>
        </div>

        <div className="mb-4">
          <img
            src="path_to_image_3"
            className="img-fluid mb-3"
            alt="Nakliye Kullanım Ekranı 3"
          />
          <p>
            Nakliye yazılımı, esnek yapılandırma seçenekleri sunar ve farklı
            işletme ihtiyaçlarına uyum sağlar. Kullanıcı dostu arayüzü
            sayesinde, minimum eğitim ile maksimum verimlilik elde edilir.
          </p>
        </div>

        <div className="mb-4">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/example_video_3"
            title="Nakliye Yazılımı Kullanım Videosu"
            allowFullScreen
          ></iframe>
          <p>
            Yukarıdaki video, Nakliye yazılımının kullanımını ayrıntılı bir
            şekilde açıklamaktadır. Videoda, yazılımın tüm özellikleri ve
            işlevleri adım adım gösterilmektedir.
          </p>
        </div>

        <div className="mb-4">
          <img
            src="path_to_image_4"
            className="img-fluid mb-3"
            alt="Nakliye Kullanım Ekranı 4"
          />
          <p>
            Yazılımın güçlü veri yönetimi özellikleri, nakliye süreçlerini
            optimize eder ve tüm iş akışlarını verimli bir şekilde yönetmenizi
            sağlar. Ayrıca, rotalama ve maliyet hesaplamaları gibi karmaşık
            işlemleri otomatikleştirerek, zaman ve maliyet tasarrufu sağlar.
          </p>
        </div>

        <div className="mb-4">
          <img
            src="path_to_image_5"
            className="img-fluid mb-3"
            alt="Nakliye Kullanım Ekranı 5"
          />
          <p>
            İleri düzey raporlama özellikleri sayesinde, Nakliye yazılımı,
            işletmenizin performansını analiz etmenize ve stratejik kararlar
            almanıza yardımcı olur. Yazılım, tüm verileri detaylı bir şekilde
            analiz eder ve kullanıcı dostu raporlar sunar.
          </p>
        </div>

        <div className="mb-4">
          <img
            src="path_to_image_6"
            className="img-fluid mb-3"
            alt="Nakliye Kullanım Ekranı 6"
          />
          <p>
            Nakliye yazılımı, farklı kullanıcı rolleri ve yetkilendirmeler ile
            her seviyede güvenli erişim sağlar. Yöneticiler, operasyonların
            genel görünümünü kolayca izleyebilir, analiz edebilir ve gerekli
            aksiyonları alabilirler.
          </p>
        </div>
      </div>
    </section>
  );
}

export default NakliyeDetail;
