import React, { useState, useEffect } from "react";
import { useUpdateUpdateByIdMutation } from "../slices/redux/UpdateSlice";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setReloadTable } from "../slices/auth/authSlice";
import { FaBackspace, FaSave, FaSyncAlt } from "react-icons/fa";
import moment from "moment"

const DuyuruForm = ({ selectedDuyuru, handleCancel }) => {
  const dispatch = useDispatch();
  const [updateUpdateById, { data: upInfo, isLoading, isError, message }] =
    useUpdateUpdateByIdMutation();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const [duyuru, setDuyuru] = useState({
    id: "",
    tarih: "",
    baslik: "",
    duyuruMetni: "",
    resimUrl: "",
    videoUrl: "",
  });
  let { tarih, baslik, duyuruMetni, resimUrl, videoUrl } = duyuru;

  useEffect(() => {
    if (selectedDuyuru) {
      setDuyuru(selectedDuyuru);
    } else {
      setDuyuru({
        id: "",
        tarih: "",
        baslik: "",
        duyuruMetni: "",
        resimUrl: "",
        videoUrl: "",
      });
    }
  }, [selectedDuyuru]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDuyuru({ ...duyuru, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await updateUpdateById(duyuru).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    setDuyuru({
      id: "",
      tarih: "",
      baslik: "",
      duyuruMetni: "",
      resimUrl: "",
      videoUrl: "",
    });
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      dispatch(setReloadTable(true));
    }
  }, [upInfo, dispatch]);

  return (
    <>
      {isLoading && <Spinner />}
      <section className="d-flex flex-row gap-3">
        <div className="d-flex flex-column col-6">
          <div className="form-group">
            <label>Başlık</label>
            <input
              type="text"
              className="form-control"
              name="baslik"
              value={baslik}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Duyuru Metni</label>
            <textarea
              className="form-control"
              name="duyuruMetni"
              value={duyuruMetni}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column col-6">
          <div className="form-group">
            <label>Resim URL</label>
            <input
              type="text"
              className="form-control"
              name="resimUrl"
              value={resimUrl}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Video URL</label>
            <input
              type="text"
              className="form-control"
              name="videoUrl"
              value={videoUrl}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Tarih</label>
            <input
              type="date"
              className="form-control"
              name="tarih"
              value={tarih}
              onChange={handleChange}
            />
          </div>
        </div>
      </section>
      <div className="d-flex flex-column col-6">
        <div className="form-group">
          {selectedDuyuru ? (
            <div className="d-flex flex-row gap-1">
              <button
                type="button"
                className="btn btn-primary w-100 col-8 mt-1"
                onClick={handleSubmit}
              >
                <FaSyncAlt /> Güncelle
              </button>
              <button
                type="button"
                className="btn btn-secondary w-100 col-4"
                onClick={() => handleCancel()}
              >
                <FaBackspace /> Vazgeç
              </button>
            </div>
          ) : (
            <div className="form-group col-5">
              <label></label>
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={handleSubmit}
              >
                <FaSave /> Kaydet
              </button>
            </div>
          )}
        </div>
      </div>
      {isError && <div className="alert alert-danger">{message}</div>}
    </>
  );
};

export default DuyuruForm;
