import { configureStore } from '@reduxjs/toolkit';
import apiSlice from "./slices/apiSlice";
import authReducer from "./slices/auth/authSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    dynoAdminAuth: authReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
