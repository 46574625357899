import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import About from "./components/About";
import Contact from "./components/Contact";
import Yazilimlarimiz from "./components/Yazilimlarimiz";
import Referanslarimiz from "./components/Referanslarimiz";
import NelerKodluyoruz from "./components/NelerKodluyoruz";
import DynoblastDetail from "./components/DynoblastDetail";
import MahsulDetail from "./components/MahsulDetail";
import NakliyeDetail from "./components/NakliyeDetail";
import FAQ from "./components/FAQ";
import OurTeam from "./components/OurTeam";
import FiyatlarPage from "./components/FiyatlarPage";
import NotFound from "./components/NotFound"; // NotFound bileşenini ekleyin
import Meta from "./components/Meta";
import SiteMapPage from "./components/SiteMapPage";
import LoginPage from "./admin/LoginPage";
import DashBoard from "./admin/DashBoard";
import { useDispatch } from "react-redux";
import { logout } from "./slices/auth/authSlice";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   window.onbeforeunload = () => {
  //     dispatch(logout());  // logout action'ını tetikleyin
  //   };
  // }, []);

  const renderComponent = () => {
    switch (location.pathname) {
      case "/dynoblastdetail":
        return <DynoblastDetail />;
      case "/mahsuldetail":
        return <MahsulDetail />;
      case "/nakliyedetail":
        return <NakliyeDetail />;
      case "/login":
        return <LoginPage />;
      case "/dashboard":
        return <DashBoard />;
      case "/sitemap":
        return <SiteMapPage />;
      default:
        return <NotFound />; // Geçersiz bir rota varsa NotFound bileşenini render et
    }
  };

  return (
    <div className="App">
      <Navbar />
      <Meta />

      {location.pathname === "/" ? (
        <>
          <Hero />
          <About />
          <Yazilimlarimiz />
          <FiyatlarPage />
          <Referanslarimiz />
          <NelerKodluyoruz />
          <FAQ />
          <OurTeam />
          <Contact />
        </>
      ) : (
        renderComponent() // Dinamik bileşen render fonksiyonunu çağırın
      )}

      <Footer />
    </div>
  );
}

export default App;
