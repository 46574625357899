export const SERVER_URL = "http://localhost:5938/api"
export const SERVERPICT_URL = "http://localhost:5938/getresim"

  // export const SERVER_URL = "https://dynoblastizmir.com.tr:5938/api"
  // export const SERVERPICT_URL = "https://dynoblastizmir.com.tr:5938/getresim"

export const AUTH_URL = '/users';
export const MUSTERI_URL = '/musteri';
export const SATIS_URL = '/satis';
export const TAHSILAT_URL = '/tahsilat';
export const UPDATE_URL = '/update';
export const SUNUCU_URL = '/sunucu';

