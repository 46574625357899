import React, { useEffect, useState } from 'react';
import MusteriTable from './MusteriTable';
import MusteriForm from './MusteriForm';
import { useGetMusterisQuery } from '../slices/redux/MusteriSlice';
import Spinner from '../components/Spinner';
import { useSelector } from 'react-redux';

const MusteriTanim = () => {
    const {reloadTable} = useSelector(state => state.dynoAdminAuth);
    const {data: musteriTable, isLoading, isError, message, refetch} = useGetMusterisQuery()
    const [musteriler, setMusteriler] = useState([]);
    const [selectedMusteri, setSelectedMusteri] = useState(null);

    useEffect(() => {
        if (musteriTable) {
            setMusteriler(musteriTable);
        }
    }, [musteriTable]); 

    const handleEdit = (musteri) => {
        setSelectedMusteri(musteri);
    };

    const handleDelete = (musteri) => {
        setMusteriler(musteriler.filter((m) => m.customerID !== musteri.customerID));
    };

    useEffect(() => {
        if (reloadTable) {
            refetch();
        }
    }, [reloadTable, refetch]);

    return (
        <div className="tab-menu">
            {isLoading && <Spinner />}
            <MusteriForm selectedMusteri={selectedMusteri} handleCancel={() => setSelectedMusteri(null)} />
            <hr className='my-2'/>
            <MusteriTable musteriler={musteriler} onEdit={handleEdit} onDelete={handleDelete} />
            {isError && <div className="error-message">{message}</div>}
        </div>
    );
};

export default MusteriTanim;
