import React from "react";

function FAQ() {
  return (
    <section className="faq py-5 bg-light">
      <div className="container">
        <h2 className="text-center text-primary mb-5">Sıkça Sorulan Sorular</h2>
        <div className="accordion" id="faqAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Yazılım çözümleriniz hangi sektörlere hitap ediyor?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yazılım çözümlerimiz, sağlık, eğitim, lojistik, üretim ve daha birçok farklı sektöre hitap etmektedir. Her sektöre özel geliştirdiğimiz yazılım çözümleri ile iş süreçlerinizi optimize etmeye yardımcı oluyoruz.
              </div>
            </div>
          </div>
          
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Projelerde nasıl bir çalışma süreci izliyorsunuz?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Projelerde, müşteri taleplerini anlayarak kapsamlı bir analiz yapıyoruz. Tasarım ve geliştirme aşamalarını dikkatle takip ediyor, müşterilerimizden düzenli geri bildirim alarak projeyi başarılı bir şekilde tamamlıyoruz.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Yazılım geliştirme süresi ne kadar sürüyor?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yazılım geliştirme süresi, projenin kapsamına, karmaşıklığına ve müşteri taleplerine göre değişiklik gösterebilir. Ancak, genellikle ortalama bir proje 3 ila 6 ay arasında tamamlanmaktadır.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Hangi teknolojileri kullanıyorsunuz?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Projelerimizde en son teknolojileri kullanıyoruz. Başlıca kullandığımız teknolojiler arasında React, Node.js, Python, .NET, ve SQL/NoSQL veritabanları bulunmaktadır. İhtiyaca göre teknoloji yelpazemizi genişletebiliyoruz.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Yazılım geliştirme sürecine müşteriler nasıl dahil ediliyor?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Müşterilerimiz, geliştirme sürecinin her aşamasına dahil edilir. Düzenli toplantılar yaparak, ilerleme hakkında geri bildirim alır ve müşteri taleplerine göre geliştirmeleri yaparız. Bu, nihai ürünün tam olarak müşteri ihtiyaçlarını karşılamasını sağlar.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Yazılım teslim edildikten sonra destek sağlıyor musunuz?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Evet, yazılım teslim edildikten sonra da destek sağlamaktayız. Her türlü teknik destek, hata düzeltmeleri ve gerekli güncellemeler için müşterilerimize belirli bir süre boyunca ücretsiz destek veriyoruz.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                Müşterilere özel yazılım çözümleri sunuyor musunuz?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Evet, müşterilerimizin ihtiyaçlarına özel yazılım çözümleri sunuyoruz. Her müşterinin taleplerine göre projeyi şekillendirir, tamamen özelleştirilmiş bir yazılım geliştiririz.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                Yazılım güvenliği nasıl sağlanıyor?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yazılım güvenliği, projelerimizin en önemli önceliklerinden biridir. Güvenlik açıklarını en aza indirmek için OWASP standartlarına uygun olarak geliştiriyor ve düzenli güvenlik testleri yapıyoruz. Ayrıca, veri şifreleme ve güvenli erişim protokollerini de entegre ediyoruz.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingNine">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                Yazılım lisanslama modeli nasıl çalışıyor?
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              aria-labelledby="headingNine"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Yazılım lisanslama modeli, proje kapsamına ve müşteri taleplerine göre değişiklik göstermektedir. Genellikle, yıllık lisans ücretleri, kullanım bazlı lisanslama veya ömür boyu lisanslama gibi farklı modeller sunuyoruz.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTen">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                Proje sonrasında ek özellikler ekleyebilir miyiz?
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              aria-labelledby="headingTen"
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body">
                Evet, proje sonrasında ek özellikler eklemek mümkündür. Müşteri taleplerine göre yazılımı güncelleyebilir ve yeni fonksiyonlar ekleyebiliriz. Bu süreç, yeni bir proje olarak ele alınabilir ve ayrı bir sözleşme ile belirlenir.
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default FAQ;
