import React, { useState } from "react";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { FaEdit, FaTrashAlt } from "react-icons/fa"

const MusteriTable = ({ musteriler, onEdit, onDelete }) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [musteriToDelete, setMusteriToDelete] = useState(null);

  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const confirmDelete = (musteri) => {
    setMusteriToDelete(musteri);
    setDeleteDialogVisible(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteMusteri = () => {
    onDelete(musteriToDelete);
    hideDeleteDialog();
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>İşlemler</th>
            <th>Firma Adı</th>
            <th>İrtibat Kişisi</th>
            <th>Adres</th>
            <th>Sub Domain</th>
            <th>Veritabanı Adı</th>
            <th>Node Port</th>
          </tr>
        </thead>
        <tbody>
          {musteriler.slice(first, first + rows).map((musteri) => (
            <tr key={musteri.customerID}>
              <td className="d-flex flex-row gap-1">
                <button
                  className="btn btn-warning btn-sm"
                  onClick={() => onEdit(musteri)}
                >
                 <FaEdit /> Düzenle
                </button>
                <button
                  className="btn btn-danger btn-sm ml-2"
                  onClick={() => confirmDelete(musteri)}
                >
                 <FaTrashAlt /> Sil
                </button>
              </td>
              <td>{musteri.customerName}</td>
              <td>{musteri.customerPerson}</td>
              <td>{musteri.customerAddress}</td>
              <td>{musteri.customerDomain}</td>
              <td>{musteri.customerDBName}</td>
              <td>{musteri.customerRunPort}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={musteriler.length}
        onPageChange={onPageChange}
      />

      <Dialog
        header="Müşteri Sil"
        visible={deleteDialogVisible}
        onHide={hideDeleteDialog}
        footer={
          <div>
            <button className="btn btn-danger" onClick={deleteMusteri}>
              Evet
            </button>
            <button className="btn btn-secondary" onClick={hideDeleteDialog}>
              Hayır
            </button>
          </div>
        }
      >
        <p>Bu müşteriyi silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </div>
  );
};

export default MusteriTable;
