import React, { useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import {
  useAddTahsilatMutation,
  useDeleteTahsilatByIdMutation,
  useGetTahsilatsQuery,
} from "../slices/redux/TahsilatSlice";
import { FaBackspace, FaSave, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setReloadTable } from "../slices/auth/authSlice";
import Spinner from "../components/Spinner";
import { Dialog } from "primereact/dialog";
import { primeReactStyle } from "../components/PrimeReactUtil";
import moment from "moment"

const TahsilatIslemleri = ({ customerID }) => {
  const dispatch = useDispatch();
  const {
    data: tahsilatTable,
    isLoading,
    isError,
    message,
    refetch
  } = useGetTahsilatsQuery(customerID);
  const [addTahsilat, { data: addInfo }] = useAddTahsilatMutation();
  const [deleteTahsilatById, { data: delInfo }] = useDeleteTahsilatByIdMutation();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [tahsilatData, setTahsilatData] = useState({
    tarih: new Date(),
    tahsilatTutari: "",
    aciklama: "",
  });
  const { tahsilatTutari, aciklama } = tahsilatData;
  const [visible, setVisible] = useState(false);
  const [silID, setSilID] = useState("");

  const silSorModul = (satis) => {
    setSilID(satis.saleID);
    setVisible(true);
  };


  const onPageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const onChange = (e) => {
    setTahsilatData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const tahsilatKaydet = async () => {
    if (tahsilatTutari === "" && aciklama === "") {
      toast.error("Lütfen başlangıç ve bitiş tarihlerini seçin.");
      return;
    }
    let data = { ...tahsilatData, customerID };
    try {
      await addTahsilat(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      dispatch(setReloadTable(true));
    }
  }, [addInfo, dispatch]);

  const handleDeleteTahsilat = async () => {
    try {
      await deleteTahsilatById(silID).unwrap();
      setVisible(false);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [delInfo, refetch]);

  useEffect(() => {
    if(!customerID) {
      refetch()
    }
  },[customerID, refetch])

  return (
    <div className="col-4">
      {isLoading && <Spinner />}
      <div className="row">
        <div className="col-12 d-flex flex-column gap-3 mb-2">
          <div className="col-12">
            <div className="row">
              <label className="col-4 mt-2">Tahsilat Tutarı</label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  name="tahsilatTutari"
                  value={tahsilatTutari}
                  onChange={onChange}
                  placeholder="Tahsilat Tutarı"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <label className="col-4 mt-2">Açıklama</label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  name="aciklama"
                  value={aciklama}
                  onChange={onChange}
                  placeholder="Açıklama"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-100 mt-2">
        <button className="btn btn-success" onClick={tahsilatKaydet}>
          <FaSave /> Kaydet
        </button>
      </div>
      <hr className="my-2" />
      <table className="table table-striped">
        <thead>
          <tr>
            <th>İşlem</th>
            <th>Tarih</th>
            <th>Tutar</th>
            <th>Açıklama</th>
          </tr>
        </thead>
        {tahsilatTable && tahsilatTable.length > 0 && (
          <tbody>
            {tahsilatTable.slice(first, first + rows).map((tahsilat) => (
              <tr key={tahsilat.claimID}>
                <td><button className="btn btn-danger" onClick={() => silSorModul(tahsilat)}>Sil</button></td>
                <td>{moment(tahsilat.dateClaim).format("DD.MM.YYYY")}</td>
                <td>{tahsilat.collection}</td>
                <td>{tahsilat.explainClaim}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={tahsilatTable && tahsilatTable.length}
        onPageChange={onPageChange}
      />
      {isError && <p className="text-danger">{message}</p>}
      <Dialog
        header="Kayıt Silme Onayı"
        visible={visible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu <strong>Tahsilat Kaydı</strong>{" "}
            Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDeleteTahsilat}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </div>
  );
};

export default TahsilatIslemleri;
