import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useDeleteSunucuByIdMutation } from "../slices/redux/ServerSlice";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { Paginator } from "primereact/paginator";

const ServerTable = ({ onEdit, sunucular }) => {
  const dispatch = useDispatch();
  const [deleteSunucuById] = useDeleteSunucuByIdMutation();

  const [first, setFirst] = useState(0); // İlk satır indeksi
  const [rows, setRows] = useState(7); // Her sayfada gösterilecek satır sayısı

  const handleDelete = (id) => {
    dispatch(deleteSunucuById(id))
      .unwrap()
      .then(() => toast.success("Sunucu başarıyla silindi"))
      .catch(() => toast.error("Silme sırasında hata oluştu"));
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const currentSunucular = sunucular.slice(first, first + rows);

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "15%" }}>İşlemler</th>
            <th style={{ width: "20%" }}>Firma Adı</th>
            <th style={{ width: "15%" }}>www Url</th>
            <th style={{ width: "15%" }}>Kullanıcı Adı</th>
            <th style={{ width: "15%" }}>Hizmet Adı</th>
            <th style={{ width: "10%" }}>Bitiş Tarihi</th>
          </tr>
        </thead>
        {sunucular && sunucular.length > 0 ? (
          <tbody>
            {currentSunucular.map((server) => (
              <tr key={server.sunucuID}>
                <td className="d-flex flex-row gap-1">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() => onEdit(server)}
                  >
                    <FaEdit /> Düzenle
                  </button>
                  <button
                    className="btn btn-danger btn-sm ml-2"
                    onClick={() => handleDelete(server.sunucuID)}
                  >
                    <FaTrashAlt /> Sil
                  </button>
                </td>
                <td>{server.sunucuFirma}</td>
                <td>{server.sunucuURL}</td>
                <td>{server.sunucuUserName}</td>
                <td>{server.sunucuHizmeti}</td>
                <td>{server.sunucuBitisTarihi}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tr>
            <td colSpan="7">Sunucu bulunamadı.</td>
          </tr>
        )}
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={sunucular && sunucular.length}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default ServerTable;
