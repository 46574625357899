import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const baseUrl = "https://www.dynoblastizmir.com.tr"; // Bu URL'i kendi domain adınıza göre güncelleyin

const staticXML = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>${baseUrl}/</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>1.0</priority>
  </url>
  <url>
    <loc>${baseUrl}/about</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.8</priority>
  </url>
  <url>
    <loc>${baseUrl}/yazilimlarimiz</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.8</priority>
  </url>
  <url>
    <loc>${baseUrl}/dynoblastdetail</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>${baseUrl}/mahsuldetail</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>${baseUrl}/nakliyedetail</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.7</priority>
  </url>
  <url>
    <loc>${baseUrl}/testimonial</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.6</priority>
  </url>
  <url>
    <loc>${baseUrl}/contact</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.6</priority>
  </url>
  <url>
    <loc>${baseUrl}/sitemap</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <priority>0.5</priority>
  </url>
</urlset>`;

const parseXMLToHTML = (xml) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "application/xml");
  const urls = Array.from(xmlDoc.getElementsByTagName("url"));
  return urls.map((url, index) => {
    const loc = url.getElementsByTagName("loc")[0].textContent;
    return (
      <li key={index}>
        <a href={loc}>{loc}</a>
      </li>
    );
  });
};

const SiteMapPage = () => {
  const navigate = useNavigate();
  const [parsedHTML, setParsedHTML] = useState(null);

  useEffect(() => {
    const htmlContent = parseXMLToHTML(staticXML);
    setParsedHTML(htmlContent);
  }, []);

  return (
    <div>
      <button
        className="btn btn-secondary mx-2 my-2 w-25"
        onClick={() => navigate("/")}
        style={{ padding: "5px" }}
      >
        Geri
      </button>
      <h1>Site Map</h1>
      <ul>{parsedHTML}</ul>
    </div>
  );
};

export default SiteMapPage;
