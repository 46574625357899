import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import software1 from "../assets/app/dynoblastbarkod.png";
import androidCihaz from "../assets/S50-2.png";

const FiyatlarPage = () => {
  const yazilimOzellikleri = [
    "PATBİS Uyumlu",
    "Bulut Tabanlı",
    "Tam Güvenlik",
    "Çok Kullanıcılı",
    "Mobil Uyumlu",
    "Android Sistemli",
    "Kolay Kullanım"
  ];

  const yazilimDetay = "Patlayıcı madde kullanımı yapan maden ocakları ve benzeri alanlar için, PATBİS sistemine tam entegre raporlar hazırlayan ve size sunan profesyonel bir uygulamadır.";

  const barkodDetay = "Dynoblast Yazılımı Offline ve Online Çalışan, Maden Ocakları İçin Özel Üretilmiş Android Barkod Okuyucu";

  return (
    <div className="price" id="price">
      <div className="container mt-5 mb-5">
        <div className="section-header text-center mt-5">
          <h1 className="mb-5">Dynoblast Depo Yönetim Yazılımı</h1>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="price-item">
              <div className="price-header">
                <h1 className="font-24">Yazılım Özellikleri</h1>
              </div>
              <div className="price-body">
                <ul>
                  {yazilimOzellikleri.map((ozellik, index) => (
                    <li key={index}>
                      <h1 className="font-16"><FaCheckCircle color="green" /> {ozellik}</h1>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="price-item featured-item">
              <div className="price-header">
                <h1 className="font-24">Maden Ocakları İçin</h1>
              </div>
              <img src={software1} className="card-img-top mb-2 p-2" alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" />
              <div className="price-body">
                <p className="p-2">{yazilimDetay}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="price-item">
              <div className="price-header">
                <h1 className="font-24">Barkod Okuyucu Cihaz</h1>
              </div>
              <img src={androidCihaz} className="card-img-top p-1 mb-4" alt="Serhat, Zafer, Ülgür, React, JS, Node, JS, Web, Uygulaması, Türkiye, Ankara, Software, Web, Developer" style={{ width: "100px"}}/>
              <div className="price-body">
                <p>{barkodDetay}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="price-footer text-center mb-5">
          <h3 className="d-none">
            Fiyat: <span className="orange">2500</span> $ + KDV
          </h3>
        </div>
      </div>
    </div>
  );
};

export default FiyatlarPage;
