export const primeReactStyle = {
    deleteHeaderStyle : {
        padding: "10px",
        backgroundColor: "#A91D3A",
        color: "white",
        textAlign: "center"
    },
    deleteContentStyle : {
        padding: "10px",
        backgroundColor: "#F0EBE3",
        color: "black",
    },
    editHeaderStyle : {
        padding: "10px",
        backgroundColor: "#074173",
        color: "white",
        textAlign: "center"
    },
    editContentStyle: {
        padding: "10px",
        backgroundColor: "#F0EBE3",
        color: "black",
    },
    changeHeaderStyle : {
        padding: "10px",
        backgroundColor: "#32012F",
        color: "white",
        textAlign: "center"
    },
    changeContentStyle: {
        padding: "10px",
        backgroundColor: "#F0EBE3",
        color: "black",
    },
    autoCompleteStyle: {
        height: "34px",
        width: "100%"
    },
    autoCompleteInputStyle: {
        padding: "5px"
    },
    calenderStyle: {
        height: "38px"
    },
    calenderInputStyle: {
        padding: "5px"
    }
}