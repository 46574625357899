import React, { useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useGetMusterisQuery } from "../slices/redux/MusteriSlice";
import SatisIslemleri from "./SatisIslemleri";
import TahsilatIslemleri from "./TahsilatIslemleri";
import ToplamBorclar from "./ToplamBorclar";

const MusteriIslemleri = () => {
  const [filteredMusteriler, setFilteredMusteriler] = useState([]);
  const [selectedMusteri, setSelectedMusteri] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [musteriID, setMusteriID] = useState(null);

  const { data: musteriler } = useGetMusterisQuery();

  const searchMusteriler = (event) => {
    setFilteredMusteriler(
      musteriler.filter((musteri) =>
        musteri.customerName.toLowerCase().includes(event.query.toLowerCase())
      )
    );
  };

  const onMusteriChange = (e) => {
    const selected = e.value;
    setSelectedMusteri(selected);
    setMusteriID(null)
    setCustomerID(selected ? selected.customerID : null);
  };

  const musteriIDSet = () => {
    setMusteriID(customerID)
  }

  return (
    <div className="tab-menu">
      <div className="d-flex justify-content-between align-items-center">
        <div className="row mb-1 col-4">
          <div className="col-12 d-flex flex-row">
            <label className="col-4 mt-1">Müşteri Seç</label>
            <div className="col-12 d-flex flex-row gap-1">
              <AutoComplete
                value={selectedMusteri}
                suggestions={filteredMusteriler}
                completeMethod={searchMusteriler}
                field="customerName"
                onChange={onMusteriChange}
                placeholder="Müşteri Ara..."
                dropdown
                inputStyle={{
                  background: "white",
                  color: "black",
                  padding: "5px",
                }}
                style={{ height: "36px", width: "100%" }}
              />
              <button className="btn btn-primary" onClick={() => musteriIDSet()}>Sorgula</button>
            </div>
          </div>
        </div>
        <h1 className="text-center col-6 font-24">
          Müşteri Satış ve Tahsilat İşlemleri
        </h1>
      </div>
      <hr className="my-2" />
      {!customerID && (
        <div className="alert alert-warning">Lütfen bir müşteri seçiniz.</div>
      )}
      <div className="row">
        <SatisIslemleri customerID={musteriID} />
        <TahsilatIslemleri customerID={musteriID} />
      </div>
      <hr className="my-2" />
      <ToplamBorclar customerID={musteriID} />
    </div>
  );
};

export default MusteriIslemleri;
